import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../SVGAvatars/fullLogo.svg";
import { UserContext } from "../context/AuthContext";
import backdrop from "./login.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SignIn() {
  const [openErr, setOpenErr] = useState(false);
  const [openSuc, setOpenSuc] = useState(false);

  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setOpenErr(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;

    console.log(email,password)
    try {
      const res = await axios.post("https://api.heygyani.com/login/", {
        email: email.value,
        password: password.value,
      });
      localStorage.setItem("token", res.data[1]["access"]);
      localStorage.setItem("user", JSON.stringify(res.data[0]));
      localStorage.setItem("first_time_user", res.data[2]);
      setUser(res.data);
      setOpenSuc(true);

      setTimeout(() => {
        window.location = "/dashboard/home";
      }, 1000); // Redirect after success
    } catch (error) {
      setOpenErr(true);
      console.error(error);
    }
  };

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 min-h-screen">
  {/* Left Section */}
  <div className="flex flex-col items-center justify-center bg-white px-6 py-8 lg:py-0">
    <div className="absolute top-4 left-4 lg:top-6 lg:left-6">
      <img
        className="w-[6rem] h-[2.5rem]"
        src={logo}
        alt="Logo"
      />
    </div>
    <h2 className="font-bold text-3xl lg:text-4xl text-gray-900 leading-snug mb-2">
      Enter your password to get started!
    </h2>
    <h3 className="font-medium text-lg lg:text-xl text-gray-700 mb-6">
      Just a few more steps and you're finished! We hate paperwork, too.
    </h3>
    <form
      className="flex flex-col items-center w-full max-w-md"
      onSubmit={handleSubmit}
    >
      <input
        type="email"
        name="email"
        placeholder="EMAIL ADDRESS"
        className="w-full h-12 px-4 mt-4 rounded-md bg-gray-100 border focus:border-blue-500 focus:ring focus:ring-blue-300 outline-none"
        required
      />
      <input
        type="password"
        name="password"
        placeholder="PASSWORD"
        className="w-full h-12 px-4 mt-4 rounded-md bg-gray-100 border focus:border-blue-500 focus:ring focus:ring-blue-300 outline-none"
        required
      />
      {openErr && (
        <p className="text-red-500 mt-2 text-sm">Something went wrong.</p>
      )}
      {openSuc && (
        <p className="text-green-500 mt-2 text-sm">Login Successful!</p>
      )}
      <div className="flex justify-between w-full mt-2 text-sm text-gray-600">
        <span
          onClick={() => navigate("/forget")}
          className="cursor-pointer text-blue-500 hover:underline"
        >
          Forgot Password?
        </span>
        <span
          onClick={() => navigate("/signup")}
          className="cursor-pointer text-blue-500 hover:underline"
        >
          New User? Sign up
        </span>
      </div>
      <button
        className="w-full mt-6 h-12 rounded-md bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
        type="submit"
      >
        LOGIN
      </button>
    </form>
  </div>

  {/* Right Section */}
  <div className="relative flex lg:flex hidden lg:flex-col lg:gap-10 items-center justify-center bg-gray-100">
    <img
      className="top-4 lg:top-8 w-[12rem] lg:w-[12rem]"
      src={logo}
      alt="Logo"
    />
    <img
      className="w-full max-h-screen object-cover"
      src={backdrop}
      alt="Backdrop"
    />
  </div>

  {/* Mobile SVG */}
  <div className="lg:hidden flex justify-center lg:py-6">
    <img
      className="w-auto lg:w-[12rem]"
      src={backdrop}
      alt="Backdrop for Mobile"
    />
  </div>
</div>

  );
}

export default SignIn;
