import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/AuthContext";
import { useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import logo from "../../SVGAvatars/fullLogo.svg";
import backdrop from "./login.png";
import image1 from "../../SVGAvatars/Avatar-01.svg";
import image2 from "../../SVGAvatars/Avatar-02.svg";
import image3 from "../../SVGAvatars/Avatar-03.svg";
import image4 from "../../SVGAvatars/Avatar-04.svg";
import image5 from "../../SVGAvatars/Avatar-05.svg";
import image6 from "../../SVGAvatars/Avatar-06.svg";
import image7 from "../../SVGAvatars/Avatar-07.svg";
import image8 from "../../SVGAvatars/Avatar-08.svg";
import { SendToMobile } from "@mui/icons-material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Signup() {
  const [signup, setSignup] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSuc, setOpenSuc] = useState(false);
  const [verified, setVerified] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [otpSnack, setOtpSnack] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();
  const images = [image1, image2, image3, image4, image5, image6, image7, image8];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, firstname, lastname, email, password } = e.target.elements;

    console.log({
      username: username.value,
        first_name: firstname.value,
        last_name: lastname.value,
        email: email.value,
        password: password.value,
        avatar: selectedImage,
    })

    

    try {
      const signedUpUser = await axios.post("https://api.heygyani.com/signup/", {
        username: username.value,
        first_name: firstname.value,
        last_name: lastname.value,
        email: email.value,
        password: password.value,
        avatar: selectedImage,
      });

      if (signedUpUser.status === 201) {
        localStorage.setItem("user", JSON.stringify(signedUpUser.data));
        localStorage.setItem("userAvatar", selectedImage);
        alert("User Created successfully");
        setSignup({
          email: email.value,
          password: password.value,
        });
        setUser(signedUpUser.data);
      } else {
        alert("User Creation Failed");
      }
    } catch (error) {
      alert("User Already Exists!");
      setSignup({
        email: email.value,
        password: password.value,
      });
      setOpen(true);
    }
  };

  

  useEffect(() => {
    const loginUser = async () => {
      if (signup) {
        try {
          const userDetails = await axios.post("https://api.heygyani.com/login/", {
            email: signup.email,
            password: signup.password,
          }).catch(err=>alert(err)).then(res=> console.log(res));
          setUser(userDetails.data);
        } catch (error) {
          console.error(error);
        }
      }
    };
    loginUser();
  }, [signup, setUser]);

  const resendOtp = async () => {
    const { email } = document.forms[0];
  
    // Validate email format and ensure it is not empty
    const emailValue = email.value.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailValue) {
      alert("Please enter your email.");
      return;
    }
  
    if (!emailRegex.test(emailValue)) {
      alert("Please enter a valid email address.");
      return;
    }
  
    try {
      const response = await axios.post("https://api.heygyani.com/send_email_otp/", {
        email: emailValue,
      });
      if (response.data.message === "OTP sent") {
        setSentOtp(true);
        setOtpSnack(true);
      }
    } catch (error) {
      console.error(error);
      alert("Failed to send OTP. Please try again.");
    }
  };
  

  const verifyOtp = async () => {
    const { email, otp } = document.forms[0];
    try {
      const response = await axios.post("https://api.heygyani.com/verify_otp/", {
        email: email.value,
        otp: otp.value,
      });
      if (response.data.message === "OTP verified") {
        setVerified(true);
        setOtpSnack(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return user ? (
    <Navigate to="/dashboard/home" />
  ) : (
    <div className="grid lg:grid-cols-2 grid-cols-1 min-h-screen">
      {/* Left Section */}
      <div className="flex flex-col items-center justify-center bg-white px-6 py-8 lg:py-0">
        <div className="absolute top-4 left-4 lg:top-6 lg:left-6">
          <img
            className="w-[6rem] h-[2.5rem]"
            src={logo}
            alt="Logo"
          />
        </div>
        <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
          Can't wait to onboard you!
        </h2>
        <h3 className="text-lg lg:text-xl text-gray-700 mb-6">
          Just a few more steps and you're finished! We hate paperwork, too.
        </h3>
        <form className="flex flex-col items-center w-full max-w-md" onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            className="w-full h-12 px-4 mt-4 rounded-md bg-gray-100 border focus:border-blue-500 focus:ring focus:ring-blue-300 outline-none"
            required
          />
          <input
            type="text"
            name="firstname"
            placeholder="First Name"
            className="w-full h-12 px-4 mt-4 rounded-md bg-gray-100 border focus:border-blue-500 focus:ring focus:ring-blue-300 outline-none"
            required
          />
          <input
            type="text"
            name="lastname"
            placeholder="Last Name"
            className="w-full h-12 px-4 mt-4 rounded-md bg-gray-100 border focus:border-blue-500 focus:ring focus:ring-blue-300 outline-none"
            required
          />
          <div className="flex w-full h-12 mt-4 gap-2">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              disabled={verified}
              className="flex-grow h-full px-4 rounded-md bg-gray-100 border focus:border-blue-500 focus:ring focus:ring-blue-300 outline-none"
              required
            />
            <Button onClick={resendOtp} variant="contained" className="ml-2 !text-sm" sx={{fontSize:"10px"}}>
              {!sentOtp ? "OTP" : "OTP"} <SendToMobile/>
            </Button>
          </div>
          {sentOtp && (
            <div className="flex w-full h-12 mt-4">
              <input
                type="text"
                name="otp"
                placeholder="Enter OTP"
                className="flex-grow h-full px-4 rounded-md bg-gray-100 border focus:border-blue-500 focus:ring focus:ring-blue-300 outline-none"
              />
              {
                !verified &&
                <Button onClick={verifyOtp} variant="contained" className="ml-2">
                Verify OTP
                </Button>
              }
            </div>
          )}
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="w-full h-12 px-4 mt-4 rounded-md bg-gray-100 border focus:border-blue-500 focus:ring focus:ring-blue-300 outline-none"
            required
          />
          {verified && (
            <div className="flex gap-4 mt-6">
              {images.map((img, idx) => (
                <img
                  key={idx}
                  src={img}
                  alt={`Avatar-${idx}`}
                  className={`w-16 h-16 rounded-full cursor-pointer border-4 ${selectedImage === idx ? "border-blue-500" : "border-gray-300"
                    }`}
                  onClick={() => setSelectedImage(idx)}
                />
              ))}
            </div>
          )}
          {
            verified &&
            <button
            type="submit"
            className="w-full h-12 mt-6 rounded-md bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          >
            Submit
          </button>
          }
        </form>
        <div className="mt-4">
          Already have an account?{" "}
          <span
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => navigate("/signin")}
          >
            Login
          </span>
        </div>
      </div>

      {/* Right Section */}
      <div className="relative flex lg:flex hidden lg:flex-col lg:gap-10 items-center justify-center bg-gray-100">
        <img
          className="top-4 lg:top-8 w-[12rem] lg:w-[12rem]"
          src={logo}
          alt="Logo"
        />
        <img
          className="w-full max-h-screen object-cover"
          src={backdrop}
          alt="Backdrop"
        />
      </div>

      {/* Mobile SVG */}
  <div className="lg:hidden flex justify-center lg:py-6">
    <img
      className="w-auto lg:w-[12rem]"
      src={backdrop}
      alt="Backdrop for Mobile"
    />
  </div>

      {/* Snackbar */}
      <Snackbar open={otpSnack} autoHideDuration={6000} onClose={() => setOtpSnack(false)}>
        <Alert severity={verified ? "success" : "info"} onClose={() => setOtpSnack(false)}>
          {verified ? "OTP Verified" : "OTP Sent"}
        </Alert>
      </Snackbar>
    </div>

  )
}