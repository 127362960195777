import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import {
  Menu,
  ChevronLeft,
  Home,
  NotesOutlined,
  BookmarkBorderOutlined,
  ChatBubbleOutline,
  SettingsOutlined,
  AccountCircle,
  Payment,
} from "@mui/icons-material";
import gyani from "../SVGAvatars/fullLogo.svg";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Sidebar = ({ isSidebarOpen, setSidebarOpen}) => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)"); // Detect screen width

  const [mobileOpen, setMobileOpen] = useState(false); // State for mobile drawer

  const navItems = [
    { text: "Home", icon: <Home />, path: "/dashboard/home" },
    { text: "My Notes", icon: <NotesOutlined />, path: "/dashboard/mynotes" },
    { text: "Bookmarks", icon: <BookmarkBorderOutlined />, path: "/dashboard/bookmarks" },
    { text: "Chat Bot", icon: <ChatBubbleOutline />, path: "/dashboard/chatbot" },
    { text: "Profile", icon: <AccountCircle />, path: "/dashboard/profile" },
    { text: "Subscriptions", icon: <Payment />, path: "/dashboard/subscriptions" },
  ];

  const isActive = (path) => location.pathname === path;

  const toggleSidebar = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen);
      setSidebarOpen(!isSidebarOpen);
    } else {
      setSidebarOpen(!isSidebarOpen);
    }
  };

  const SidebarContent = (
    <Box
      sx={{
        width: isSidebarOpen || isMobile ? 240 : 60,
        transition: "width 0.3s",
        backgroundColor: "#EFEFFD",
        height: "100vh",
        pt: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 1 }}>
        {isSidebarOpen && (
          <img src={gyani} alt="Logo" style={{ height: 40, marginLeft: 8 }} />
        )}
        <IconButton onClick={toggleSidebar}>
          {isMobile ? <ChevronLeft /> : isSidebarOpen ? <ChevronLeft /> : <Menu />}
        </IconButton>
      </Box>

      {/* Navigation Links */}
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              sx={{
                backgroundColor: isActive(item.path) ? "#D1D5DB" : "inherit",
                color: isActive(item.path) ? "#000" : "inherit",
                "&:hover": { backgroundColor: "#D1D5DB" },
              }}
              onClick={()=> isMobile && toggleSidebar()}
            >
              <ListItemIcon
                sx={{
                  color: isActive(item.path) ? "#4A4A68" : "inherit",
                  // justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              {(isSidebarOpen || isMobile) && <ListItemText primary={item.text} />}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider />

      {/* Settings Link */}
      <List sx={{ mb: 2 }}>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/dashboard/settings"
            sx={{
              backgroundColor: isActive("/dashboard/settings") ? "#D1D5DB" : "inherit",
              color: isActive("/dashboard/settings") ? "#000" : "inherit",
              "&:hover": { backgroundColor: "#D1D5DB" },
            }}
          >
            <ListItemIcon
              sx={{
                color: isActive("/dashboard/settings") ? "#4A4A68" : "inherit",
                // justifyContent: "center",
              }}
            >
              <SettingsOutlined />
            </ListItemIcon>
            {(isSidebarOpen || isMobile) && <ListItemText primary="Settings" />}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return isMobile ? (
    <>
      {/* Top App Bar for Mobile */}
      <AppBar position="fixed">
        <Toolbar sx={{backgroundColor:"#EFEFFD",color:"black"}}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
            <Menu />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Gyani
          </Typography>
        </Toolbar>
      </AppBar>
      {/* Drawer for Mobile */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={toggleSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}

        variant="temporary"
      >
        {SidebarContent}
      </Drawer>
    </>
  ) : (
    SidebarContent
  );
};

export default Sidebar;
