import React, { useState } from "react";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import Sidebar from "./components/Sidebar"; // Assuming Sidebar is your sidebar component
import { Routes, Route, Navigate } from "react-router-dom";
import HomeLanding from "./components/research/HomeLanding";
import MyNotes from "./components/research/MyNotes";
import Bookmarks from "./components/research/Bookmarks";
import AIBot from "./components/SummaryGenerator/AIBot";
import Settings from "./components/Settings";
import Profile from "./components/user-profile/Profile";
import Subscriptions from "./components/Subsriptions/Subscriptions";
import NotFound from "./components/NotFound";

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isMobileOpen, setMobileOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)"); // Detect screen width

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Sidebar */}
      <Box sx={{ position: "fixed", height: "100vh", zIndex: 200 }}>
        <Sidebar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen}  mobileOpen={isMobileOpen} setMobileOpen={setMobileOpen}/>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          ml: isSidebarOpen ? "230px" : isMobile ? "0px" : "50px",
          pl: 2,
          mt: isMobile ? 10 : 2,
          overflowY: "auto", // Allow scrollable content
          transition: "margin 0.3s ease", // Smooth transition
        }}
      >
        <Routes>
          <Route path="/" element={<HomeLanding/>} />
          <Route path="/home" element={<HomeLanding />} />
          <Route path="/mynotes" element={<MyNotes open={isSidebarOpen} />} />
          <Route path="/bookmarks" element={<Bookmarks />} />
          <Route path="/chatbot" element={<AIBot isSidebarOpen={isSidebarOpen} />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
