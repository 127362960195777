import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import img1 from "./phonelogo.png";
import Plans from "./Plans";
import { useTranslation } from "react-i18next";
import { ArrowUpward } from "@mui/icons-material";
const axios = require("axios");

function Pricing(props) {
  const navigate = useNavigate();
  const [transactiondata, settransactiondata] = useState([]);
  const [currentplan, setcurrentplan] = useState([]);
  const [len, setlen] = useState(0);
  let user = JSON.parse(localStorage.getItem("user"));

  const { t } = useTranslation();

  useEffect(() => {
    let data = JSON.stringify({
      userid: user.id,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.heygyani.com/sub_details/",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response)
        settransactiondata(response.data);
        setlen(response.data.length);
        setcurrentplan(response.data[response.data.length - 1]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="flex flex-col scrollbar-hide">
      <div className="profile flex flex-col gap-5 w-full h-[100%] justify-center lg:justify-around items-center !scrollbar-hide">
        <h1
          className="text-[30px] lg:text-[50px] text-black font-[700] text-left w-full px-5 lg:px-10"
        >
          {t('Subscriptions')}
        </h1>

        <div className="flex flex-row justify-start items-center w-full">

          <div class="max-w-sm rounded-xl border border-gray-200 bg-white shadow-lg hover:shadow-2xl transition-shadow p-8 mx-auto">
            <div class="flex items-center space-x-3 mb-6">
              <span class="text-4xl text-blue-600">
                <i class="fas fa-cogs"></i>
              </span>
              <div>
                <h2 class="text-2xl font-semibold text-gray-800">Subscription Plan</h2>
                <p class="text-sm text-gray-500">Your current plan and remaining credits</p>
              </div>
            </div>

            <div class="mb-6">
              <p class="text-lg font-semibold text-gray-700">Current Plan</p>
              <div class="flex items-center space-x-3">
                <div class="bg-blue-100 p-2 rounded-lg">
                  <i class="fas fa-rocket text-blue-600 text-2xl"></i>
                </div>
                <p class="text-2xl font-bold text-blue-600">Gyani Plus</p>
              </div>
            </div>

            <div class="mb-6">
              <p class="text-lg font-semibold text-gray-700">Credits Left</p>
              <div class="flex items-center space-x-3">
                <div class="bg-green-100 p-2 rounded-lg">
                  <i class="fas fa-trophy text-green-600 text-2xl"></i>
                </div>
                <p class="text-2xl font-bold text-green-600">{user.credits_left} Credits</p>
              </div>
            </div>

            <div class="mt-6">
              <button class="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-semibold py-3 rounded-lg hover:from-blue-600 hover:to-indigo-700 transform hover:scale-105 transition-all duration-300">
                Upgrade Plan
              </button>
            </div>
          </div>

        </div>

      </div>
      <br />
      <Plans />
      <div className="flex flex-col w-full mb-2 mt-2 mx-10">
        <p
          className="sm:text-4xl max-[470px]:text-xl text-3xl font-bold title-font m-2 text-gray-900"
          style={{ fontFamily: "Work Sans" }}
        >
          Transaction history
        </p>
      </div>
      {len == 0 ? (
        <p
          className="text-3xl text-gray-900"
          style={{ fontFamily: "Work Sans" }}
        >
          No item available
        </p>
      ) : (
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="border-b font-medium dark:border-neutral-500">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        #
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Amount
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Transaction Id
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Invoice
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {transactiondata.map((item, index) => (
                        <tr
                          key={index}
                          className="border-b dark:border-neutral-500"
                        >
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {/* {item.amt} */}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.date_time}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.tx_id}
                          </td>
                          <>
                            {item.payment_confimation ? (
                              <td className="whitespace-nowrap text-blue-700 px-6 py-4">
                                Success
                              </td>
                            ) : (
                              <td className="whitespace-nowrap text-red-700 px-6 py-4">
                                Failed
                              </td>
                            )}
                          </>
                          <td className="whitespace-nowrap px-6 py-4">
                            download
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pricing;
